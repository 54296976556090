<script lang="ts" setup>
import { __subscription } from "~/stores/subscription.store";

import "@/assets/css/icons.css";

import { useKeycloak } from "~/composables/keycloak";
import { useMeta } from "~/composables/meta";
import { checkNotifications } from "~/composables/notifications";

const { isAuthenticated } = useKeycloak();
const { t } = useI18n();
const config = useRuntimeConfig().public;

let fontSize = "16px";
// if windows height is less than 800px, set font size to 14px
if (typeof window !== "undefined" && window.innerHeight < 800) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fontSize = "8px";
}

checkNotifications(t, config);

__subscription().checkIfNoSubscriptionPaid();
</script>

<template>
    <div v-if="isAuthenticated" class="app-container">
        <div class="top-bar"/>
        <div class="app">
            <div class="sidebar">
                <Layout-Sidebar/>
            </div>
            <div class="content">
                <div v-if="useMeta().title !== '' || useMeta().description !== ''" class="header">
                    <Layout-Header/>
                </div>
                <div class="body">
                    <div class="view">
                        <NuxtPage class="page"/>
                    </div>
                    <div class="footer">
                        <Layout-Footer/>
                    </div>
                </div>
            </div>
            <WelcomeModal/>
        </div>
    </div>
    <div v-else class="main-loader__container">
        <Loader class="main-loader" classes="h-32"/>
    </div>
</template>

<style lang="scss">
@import '@/assets/css/elements.scss';
@import '@/assets/css/variables.scss';
@import '@/assets/css/function.scss';
@import '@/assets/css/default.scss';

:root {
    font-size: 16px;

    @media (max-height: 840px) {
        font-size: 14px;
    }
    @media (max-height: 740px) {
        font-size: 12px;
    }
    @media (max-height: 680px) {
        font-size: 10px;
    }
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.main-loader {

    &__container {
        @apply flex justify-center items-center h-screen;
    }
}

.app {
    @apply bg-lighter;

    &-container {
        .top-bar {
            @apply fixed h-1 bg-primary w-full z-10;
        }
    }

    grid-template-columns: calc(180px + 1.25rem) 1fr;
    @apply fixed top-0 h-screen grid gap-4 p-4 pb-0 pr-2 w-full;

    .sidebar {
        @apply mb-4;
    }

    .content {
        @apply flex flex-col gap-4 w-full col-start-2 overflow-hidden;

        .header {
            @apply h-auto w-full flex gap-4 pr-1;
        }

        .body {
            --radius: 20px 20px 0 0;
            @apply flex flex-col gap-5 justify-between w-full h-full pr-2 overflow-y-auto overflow-x-hidden;
            @extend %scrollable;

            .view {
                @apply flex flex-col gap-4 pt-1 transition-all duration-150;

                &--is-scrolled, &--has-rightbar {
                    @apply pt-0;
                }

                &--without-rightbar {
                    @apply pt-1;
                }

                .page {
                    @apply grid gap-4;

                    &.with-rightbar {
                        @apply grid;
                        grid-template-columns: 1fr 300px;
                    }

                    .box:not(:last-child) {
                        @apply mb-4;
                    }
                }
            }
        }

        .footer {
            @apply h-auto py-2;
        }
    }
}

hr {
    @apply border-0 border-b-cream my-[16px] border-b-[2px];
}

.with-rightbar {
    @apply grid gap-5;
    grid-template-columns: 1fr 300px;
}

.page-enter-active,
.page-leave-active {
    transition: all 50ms ease-in-out;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    transform: translateY(5px);
}
</style>
