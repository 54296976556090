<script lang="ts" setup>
import { __subscription } from "~/stores/subscription.store";

const showModal = ref(false);

onMounted(() => {
    const isFirstTrialDay = computed(() => {
        if (!__subscription().isTrialS3) {
            return false;
        }

        const trialStart = __subscription().getTrialInfoS3?.startedAt ?? new Date();
        return new Date().getDate() === trialStart.getDate();
    });

    if (
        localStorage.getItem("welcome-modal") === "true"
        || !isFirstTrialDay.value
    ) {
        return;
    }
    showModal.value = true;
});

function startTrial (): void {
    localStorage.setItem("welcome-modal", "true");
    showModal.value = false;
}
</script>

<template>
    <Modal :closable="false" :is-open="!!showModal">
        <template #title>
            <h3 class="welcome-title">
                <Icon icon="bright-star"/>
                {{ $t('modals.welcome.title') }}
            </h3>
        </template>
        <p class="main-text">
            {{ $t('modals.welcome.content') }}
        </p>
        <hr/>
        <Row
            col
            gap=".25rem"
            items="center"
            justify="center"
        >
            <Button @click="startTrial">
                {{ $t('modals.welcome.cta.start_trial') }}
            </Button>
        </Row>
    </Modal>
</template>

<style lang="scss" scoped>
.welcome-title {
    @apply text-lg font-bold font-nordique w-full text-center text-secondary
    flex items-center justify-center gap-2;
}

.main-text {
    @apply text-sm font-semibold text-center;
}
</style>
