<script lang="ts" setup>
import type { MenuItemProps } from "~/types/menus";

import { useActiveRoute } from "~/composables/routes";

const props = defineProps<{
    item: MenuItemProps
    isHoverable?: boolean
    isDropdown?: boolean
    isLight?: boolean
}>();

const customClasses = computed(() => {
    const classes = [];
    if (props.item.shadowed) {
        classes.push("shadowed");
    }
    if (props.item.theme) {
        classes.push(`theme-${props.item.theme}`);
    }
    if (props.isHoverable && !props?.item?.selections) {
        classes.push("is-hoverable");
    }
    if (useActiveRoute.value === props.item.link && props.item.link.length > 0) {
        classes.push("active");
    }
    if (props.isDropdown) {
        classes.push("dropdown-item");
    }
    if (props.item.action) {
        classes.push("cursor-pointer");
    }

    if (!props.isLight) {
        classes.push("hover:bg-grey");
    }

    return classes.join(" ");
});

const isHover = ref(false);

function changeState (): void {
    if (props.isHoverable) {
        isHover.value = !isHover.value;
    }
}

function callAction (item: MenuItemProps): void {
    if (item.action) {
        item.action();
    }
}
</script>

<template>
    <NuxtLink
        v-if="!props.item.selections"
        :class="customClasses"
        :target="item.external ? '_blank' : ''"
        :to="item.link?.toString() ?? ''"
        class="item"
        @click="callAction(item)"
        @mouseenter="changeState"
        @mouseleave="changeState"
    >
        <Icon
            :icon="item.icon"
            class="icon"
            stroke-width="2px"
            width="16px"
        />
        {{ $t(item.name) }}
        <Tag v-if="item.tag">
            {{ item.tag }}
        </Tag>
    </NuxtLink>
    <div
        v-else
        :class="customClasses"
        class="item"
    >
        <Icon
            :icon="item.icon"
            class="icon"
            stroke-width="2px"
            width="16px"
        />
        {{ $t(item.name) }}
        <Tag v-if="item.tag">
            {{ item.tag }}
        </Tag>
        <div class="item-content">
            <MenuItemSelector :selections="item.selections" :selections-value="item.selectionsValue"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/elements.scss';

.item {
    @apply grid items-center gap-2 text-light border-[1px] p-2 rounded-[8px] border-transparent font-semibold
    leading-[15px] text-sm transition-all duration-200 grid-cols-[min-content,1fr];

    &.active, &.router-link-active {
        &:not(.dropdown-item) {
            @apply border-primary bg-grey;
        }
    }

    &.is-hoverable:hover {
        @apply text-secondary;
    }

    &.shadowed {
        @extend %menu-shadow;
    }

    &.theme- {
        &light {
            @apply text-dark;
        }

        &dark {
            @apply text-light;
        }

        &secondary {
            @apply text-secondary;
        }
    }

    &-content {
        grid-column: 2 span;
    }
}
</style>
