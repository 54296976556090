<script lang="ts" setup>
import { __user } from "~/stores/user.store";

import type { MenuItemProps, MenuSelector } from "~/types/menus";

const isOpen = ref(false);

const { locale } = useI18n();

const currentLanguage = ref(locale.value);

const locales = [
    {
        name: "Français",
        value: "fr"
    },
    {
        name: "English",
        value: "en"
    }
    // {
    //     name: "Jap",
    //     value: "jp"
    // }
];

function getLanguageSelections (): MenuSelector[] {
    return locales.map(currentLocale => ({
        name: currentLocale.name,
        value: currentLocale.value,
        action: (): void => {
            currentLanguage.value = locale.value;
            locale.value = currentLocale.value;
            localStorage.setItem("locale", currentLocale.value);
        }
    }));
}

const dropdownElements: MenuItemProps[] = [];
dropdownElements.push({
    name: "menu_user.parameters",
    icon: "settings",
    link: "/user/infos"
});

// if (useRuntimeConfig().public.ENVIRONMENT === "dev") {
dropdownElements.push({
    name: "menu_user.language",
    icon: "globe",
    selectionsValue: currentLanguage.value,
    selections: getLanguageSelections()
});
// }

dropdownElements.push({
    name: "menu_user.logout",
    icon: "log-out",
    link: "/logout"
});

dropdownElements.forEach((element: MenuItemProps) => {
    element.theme = "light";
});

function toggleDropdown (): void {
    isOpen.value = !isOpen.value;
}
</script>

<template>
    <div class="header-dropdown">
        <div v-if="isOpen" class="header-dropdown__fader" @click="toggleDropdown"/>
        <Button
            :active="Boolean(isOpen)"
            class="header-dropdown__btn"
            type="outline"
            @click="toggleDropdown"
        >
            <Icon icon="profile-circle" width="1.5em"/>
            <span>{{ $t('menu_user.welcome') }} <strong>{{ __user().user.firstName }}</strong></span>
            <Icon icon="nav-arrow-down" width="1.5em"/>
        </Button>
        <div v-show="isOpen" class="header-dropdown__content">
            <nav>
                <MenuItem
                    v-for="element in dropdownElements"
                    :key="element.name"
                    :item="element"
                    is-dropdown
                    is-hoverable
                    is-light
                    @click="isOpen = false"
                />
            </nav>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/elements.scss';

.header-dropdown {
    @apply relative mt-1 z-[999];

    &__fader {
        @apply fixed top-0 left-0 w-full h-full z-10;
    }

    &__btn {
        @apply text-sm;
        strong {
            @apply font-bold;
        }
    }

    &__content {
        @apply absolute top-full right-0 bg-light border border-cream rounded-2xl p-4
        mt-2 z-10 min-w-[240px] font-bold inline-flex flex-col;
        @extend %primary-shadow;

        nav {
            @apply grid w-full gap-2;
            a:last-child {
                @apply relative;
                &::after {
                    @apply absolute bottom-full left-0 w-full h-[1px] bg-cream mb-1;
                    content: '';
                }
            }
        }
    }
}
</style>
