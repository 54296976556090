<script lang="ts" setup>
import { __subscription } from "~/stores/subscription.store";

import type { MenuCategoryProps } from "~/types/menus";

import { __drivePro, hasAuthorization, RoleRules, toValue } from "#imports";
import { daysBetweenNow } from "~/composables/date";
import { toBytes } from "~/composables/units";

const config = useRuntimeConfig();

const categoryGeneral = computed<MenuCategoryProps>(() => {
    return {
        name: "menu.general",
        icon: "cloud",
        items: [
            {
                name: "menu.general_dashboard",
                icon: "home",
                link: "/",
                visible: true
            }
        ]
    };
});

const categoryPartners = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.partners",
        icon: "rocket",
        items: []
    };
    if (__subscription().hasPartner) {
        cat.items.push({
            name: "menu.partners_clients",
            icon: "community",
            link: "/partners",
            visible: true
        });
    }

    return cat;
});

const categoryS3 = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.object_storage",
        icon: "substract",
        items: []
    };

    if (__subscription().isTrialS3) {
        const s3Trial = __subscription().getTrialInfoS3;
        if (s3Trial) {
            cat.trial = {
                left: daysBetweenNow(s3Trial.until),
                used: computed<number>(() => __subscription().getInfoS3.size ?? 0) as any,
                total: toBytes(s3Trial.quota, "To"),
                link: "/user/subscriptions",
                danger: daysBetweenNow(s3Trial.until) <= 2
            };
        }
    }

    if (__subscription().hasS3) {
        if (hasAuthorization(RoleRules.ObjectStorage_Identifier)) {
            cat.items.push({
                name: "menu.object_storage_identifiers",
                icon: "key-alt-plus",
                link: "/identifiers",
                visible: true
            });
        }

        if (hasAuthorization(RoleRules.ObjectStorage_Bucket)) {
            cat.items.push({
                name: "menu.object_storage_buckets",
                icon: "bitbucket",
                link: "/buckets",
                visible: true
            });
        }

    // if (hasAuthorization(RoleRules.ObjectStorage_Usage)) {
    //     cat.items.push({
    //         name: 'menu.object_storage_usage',
    //         icon: 'graph-up',
    //         link: '/statistiques',
    //         visible: true
    //     })
    // }
    }

    return cat;
});

const categoryNextcloud = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.nextcloud_massive",
        icon: "frame-simple",
        items: []
    };

    return cat;
});

const categoryDrive = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.drives",
        icon: "folder",
        items: []
    };

    if (__subscription().hasDrivePro && (__pbUser().getUsername ? hasAuthorization(RoleRules.DrivePro) : true)) {
        const proElement = {
            name: "menu.drives_pro",
            icon: "large-suitcase",
            link: "/drive-pros",
            visible: true,
            external: false
        };

        if (__drivePro().getInstancesCount <= 1) {
            const instance = __drivePro().getFistInstance;
            proElement.link = instance && instance.url ? instance.url : "#url_pro=not-defined";
            proElement.external = true;

            if (instance && !instance.url) {
                console.error("L'url du drive pro n'est pas définie");
            }
        }

        cat.items.push(proElement);
    }

    if (__subscription().hasDrive && (__pbUser().getUsername ? hasAuthorization(RoleRules.Drive) : true)) {
        cat.items.push({
            name: "menu.drives_solo",
            icon: "shopping-bag",
            link: config.public.URL_CLOUD,
            visible: true,
            external: true
        });
    }

    return cat;
});

const categoryDiscovery = computed<MenuCategoryProps>(() => {
    const cat: MenuCategoryProps = {
        name: "menu.discover",
        icon: "eye-alt",
        items: []
    };

    if (__pbUser().getUsername ? !hasAuthorization(RoleRules.Discover) : false) {
        return cat;
    }

    if (!__subscription().hasS3) {
        cat.items.push({
            name: "menu.object_storage",
            icon: "substract",
            link: "/discover/object-storage",
            visible: true
        });
    }

    if (!__subscription().hasDrive) {
        cat.items.push({
            name: "menu.drives_solo",
            icon: "shopping-bag",
            link: "/discover/drive-perso",
            visible: true
        });
    }

    if (!__subscription().hasDrivePro) {
        cat.items.push({
            name: "menu.drives_pro",
            icon: "large-suitcase",
            link: "/discover/drive-pro",
            visible: true
        });
    }

    cat.items.push({
        name: "menu.nextcloud_massive",
        icon: "frame-simple",
        link: "/discover/nextcloud-massif",
        visible: true
    });

    return cat;
});

const menuId = ref<string>("");
const menu = computed<MenuCategoryProps[]>(() => {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    menuId.value = Math.random().toString(36).substring(7);
    const menus = [];

    menus.push(categoryGeneral);

    if (hasAuthorization(RoleRules.Partner)) {
        menus.push(categoryPartners);
    }

    if (hasAuthorization(RoleRules.ObjectStorage)) {
        menus.push(categoryS3);
    }

    menus.push(categoryDrive);
    menus.push(categoryNextcloud);
    menus.push(categoryDiscovery);

    return menus.map(c => toValue<MenuCategoryProps>(c))
        .filter(c => c.items.length > 0);
});
</script>

<template>
    <nav class="menu">
        <div v-for="category in menu" :key="`cat-${category.name}-${menuId}`" class="menu__category">
            <MenuCategory
                :icon="category.icon"
                :items="category.items"
                :name="category.name"
                :trial="category.trial ?? null"
            />
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.menu {
    @apply flex flex-col gap-6 text-sm;
}
</style>
