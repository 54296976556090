<script lang="ts" setup>
import { version } from "../../package.json";

const currentVersion = ref("1.0.0");

onMounted(() => {
    currentVersion.value = version;
});
</script>

<template>
    <div class="footer">
        <div class="footer__content">
            <Row class="left" gap="4px">
                <span>{{ $t('label.copyright') }} {{ new Date().getFullYear() }} Leviia</span>
                <span>-</span>
                <span>v{{ currentVersion }}</span>
            </Row>
            <nav class="right">
                <a href="https://leviia.com/" target="_blank">
                    {{ $t('footer.website') }}
                </a>
            </nav>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.footer {
    @apply h-full text-sm font-semibold select-none;

    .footer__content {
        @apply flex justify-between items-center h-full pr-5;

        .right {
            @apply flex gap-5 flex-row-reverse;
            > *:not(:last-child) {
                @apply relative;

                &::after {
                    content: "-";
                    @apply absolute -right-3;
                }
            }
        }
    }

    .left, .right {
        @apply w-1/2;
    }
}
</style>
