<script lang="ts" setup>
import type { Trial } from "~/types/layout";

import { fromBytes } from "~/composables/units";

const props = defineProps<{
    infos: Trial
}>();

const used = computed(() => {
    const tmp = fromBytes(props.infos.used);
    return `${tmp.value} ${tmp.unit}`;
});

const total = computed(() => {
    const tmp = fromBytes(props.infos.total);
    return `${tmp.value} ${tmp.unit}`;
});

const barPercent = computed(() => {
    const used = fromBytes(props.infos.used);
    const total = fromBytes(props.infos.total);

    if (used.unit === "To") {
        used.value *= 1000;
    }
    if (total.unit === "To") {
        total.value *= 1000;
    }

    return `${(used.value / total.value) * 100}%`;
});
</script>

<template>
    <div class="box">
        <h3>Version d'essai</h3>
        <p class="infos">
            <span class="bolder">{{ infos.left }}</span> jours restants
        </p>
        <div class="bar__box">
            <div class="bar__outer">
                <div :style="{ width: barPercent }" class="bar__inner"/>
            </div>
            <p>{{ used }} / {{ total }} !</p>
        </div>
        <NuxtLink :to="infos.link">
            <Row class="cta" items="center" justify="center">
                Prendre un abonnement
                <Icon icon="arrow-right-circle"/>
            </Row>
        </NuxtLink>
    </div>
</template>

<style lang="scss" scoped>
.box {
    @apply relative top-3 bg-secondary border-lightgrey border-2 rounded-lg p-2 mb-5;

    h3, .cta {
        @apply font-nordique font-bold;
        letter-spacing: 0.1em;
    }

    .infos {
        @apply text-xs text-dark font-semibold mt-1;

        .bolder {
            @apply font-[900];
        }
    }

    .bar__box {
        @apply flex gap-1 text-xs justify-between items-center leading-none mt-1 mb-2;
    }

    .bar__outer {
        @apply flex-1 bg-light rounded-lg bg-opacity-50 h-2 overflow-hidden;
    }

    .bar__inner {
        @apply bg-light h-2;
    }

    .cta {
        @apply text-xs font-bold;
    }
}
</style>
